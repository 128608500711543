import fetch from 'common/js/fetch';
import {getUserId} from 'common/js/util';

/**
 * 用户登录
 */
export function userLogin(config) {
  return fetch('/auth/v1/user/login/doctor', config);
}

// 用户详情
export function getUser() {
  return fetch(`/auth/v1/user/detail/${getUserId()}`);
}

// 忘记密码
export function forgetLoginPwd(config) {
  return fetch(`/auth/v1/user/forget_loginPwd`, config);
}

// 发送短信验证码
export function sendCaptcha(config) {
  return fetch(`/core/v1/sms_out/send_sms_captcha_out`, config);
}

// 发送邮箱验证码
export function sendEmailCaptcha(config) {
  return fetch(`/core/v1/sms_out/send_email_captcha_out`, config);
}

// 绑定邮箱
export function bindEmail(config) {
  return fetch(`/auth/v1/user/bind_email`, config);
}

// 修改邮箱
export function modifyEmail(config) {
  return fetch(`/auth/v1/user/modify_email`, config);
}

// 绑定手机号
export function bindMobile(config) {
  return fetch(`/auth/v1/user/bind_mobile`, config);
}

// 修改手机号
export function modifyMobile(config) {
  return fetch(`/auth/v1/user/modify_mobile`, config);
}

// 绑定交易密码
export function bindTradePaw(config) {
  return fetch(`/auth/v1/user/bind_tradePwd`, config);
}

// 修改交易密码
export function modifyTradePaw(config) {
  return fetch(`/auth/v1/user/modify_tradePwdCaptcha`, config);
}

// 修改登录密码
export function modifyLoginPaw(config) {
  return fetch(`/auth/v1/user/modify_loginPwdCaptcha`, config);
}

// 医生所在诊所
export function doctorClinic(config) {
  return fetch(`/core/v1/clinic_doctor/doctor_clinic`, config);
}

// 列表条件查询医生排班
export function doctorScheduleList(config) {
  return fetch(`/core/v1/doctor_schedule/list`, config);
}

// 新增医生排班对象
export function createDoctorSchedule(config) {
  return fetch(`/core/v1/doctor_schedule/create`, config);
}

// 修改医生排班对象
export function modifyDoctorSchedule(config) {
  return fetch(`/core/v1/doctor_schedule/modify`, config);
}

// 删除医生排班对象
export function removeDoctorSchedule(id) {
  return fetch(`/core/v1/doctor_schedule/remove/${id}`);
}

// 查询医生排班对象
export function doctorScheduleDetail(id) {
  return fetch(`/core/v1/doctor_schedule/detail/${id}`);
}

// 绑定微信号
export function bindWechatCode(config) {
  return fetch(`/auth/v1/user/bind_wechat_code`, config);
}

// 关闭临床偏好提醒
export function closeClinicalPrefReminder() {
  return fetch(`/auth/v1/user/close_clinical_pref_reminder`)
}

export function modifyUserInformation(config) {
  return fetch(`/auth/v1/user/modify`, config)
}
